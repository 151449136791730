<template>
  <v-navigation-drawer
    v-model="drawer"
    width="328px"
    color="#F6F9F6"
    :permanent="$vuetify.breakpoint.lgAndUp"
    :absolute="$vuetify.breakpoint.lgAndUp"
    :fixed="!$vuetify.breakpoint.lgAndUp"
    :temporary="!$vuetify.breakpoint.lgAndUp"
  >
    <div class="company">Homecheck</div>
    <v-list>
      <v-list-item exact :to="{ name: 'dashboard' }" link>
        <v-list-item-icon>
          <v-img max-width="24px" :src="require('@/assets/category.svg')" />
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t("dashboard") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <div v-for="item in items" :key="item.title" class="submenu">
      <v-img max-width="24px" :src="require(`@/assets/${item.icon ?? 'document'}.svg`)" />
      <v-img
        max-width="24px"
        class="hover"
        :src="require(`@/assets/${item.icon ?? 'document'}-hover.svg`)"
      />
      <div class="divider"></div>
      <v-list>
        <v-list-item
          v-if="item.to"
          link
          :to="item.to instanceof String ? { name: item.to } : item.to"
        >
          <v-list-item-content>
            <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-else>
          <v-list-item-content>
            <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-for="child in item.childrens"
          :key="child.title"
          link
          :to="typeof child.to == 'string' ? { name: child.to } : child.to"
        >
          <v-list-item-content>
            <v-list-item-title> {{ $t(child.title) }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </div>

    <template v-slot:append>
      <v-row class="ml-2 mb-3">
        <v-col cols="8">
          <profile-dialog :user="user" />
        </v-col>
        <v-col cols="3">
          <confirm-dialog @confirmed="logout">
            <template #activator="{ on, attrs }">
              <slot name="activator" v-bind:attrs="attrs" v-bind:on="on">
                <v-btn elevation="0" class="px-20 py-10" block v-bind="attrs" v-on="on">
                  <v-icon color="#E63946">mdi-logout</v-icon>
                </v-btn>
              </slot>
            </template>
            <template #title>
              {{ $t("logout") }}
            </template>
            {{ $t("logout-text") }}
          </confirm-dialog>
        </v-col>
      </v-row>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import users from "../../mixins/users";
import ProfileDialog from "./ProfileDialog";
import ConfirmDialog from "./ConfirmDialog";

export default {
  mixins: [users],
  components: {
    ProfileDialog,
    ConfirmDialog,
  },
  data: () => ({
    drawer: false,
    user: null,
  }),
  computed: {
    ...mapState(["openDrawer"]),
    ...mapGetters({
      getUserInfo: "user/userInfo",
    }),
    items: function () {
      const menuItems = [];
      if (!this.user) {
        return menuItems;
      }

      menuItems.push({ title: "bookings", to: { name: "bookings" } });

      return menuItems;
    },
  },
  watch: {
    drawer: function () {
      if (!this.drawer) {
        this.$store.dispatch("switchDrawer", false);
      }
    },
    openDrawer: function () {
      if (this.openDrawer) {
        this.drawer = true;
      }
    },
  },
  created: async function () {
    this.user = await this.getUserInfo;
  },
  methods: {
    logout: async function () {
      await this.$store.dispatch("auth/deleteAuthCookies");
      document.location.href = "";
    },
  },
};
</script>

<style lang="scss" scoped>
aside {
  border-color: rgba(0, 0, 0, 0.08);

  .v-ripple__container {
    display: none !important;
  }

  .v-list-item--link:before {
    background-color: transparent !important;
  }

  .company {
    color: #1d3557;
    margin: 40px 0 36px 0;
    font-weight: 900;
    font-size: 40px;
    line-height: 120%;
    text-transform: uppercase;
    text-align: center;
  }

  .v-list {
    .v-list-item {
      margin: 0 15px;
      border-radius: 16px;
      overflow: hidden;

      &:hover {
        background-color: transparent;
      }
    }
  }

  .submenu {
    position: relative;
    margin-bottom: -23px;
    padding-left: 50px;
    margin-top: 10px;
    overflow: hidden;

    &:has(.v-list-item--active),
    &:hover {
      .v-list .v-list-item:first-child .v-list-item__title {
        color: #1d3557;
      }

      .v-image {
        display: none;

        &.hover {
          display: block;
        }
      }

      .divider {
        background-color: #1d3557;
      }
    }

    .divider {
      position: absolute;
      height: calc(100% - 65px);
      width: 2px;
      top: 55px;
      left: 40px;
      background: #cccccc;
      border-radius: 2px;
    }

    .v-image,
    .v-icon {
      top: 15px;
      left: 30px;
      position: absolute;

      &.hover {
        display: none;
      }
    }
  }

  .v-list {
    .v-list-item {
      .v-list-item__title {
        color: #999999;
        font-weight: 500;
        font-size: 16px;
      }

      &:hover .v-list-item__title,
      &.v-list-item--active .v-list-item__title {
        color: #1d3557;
      }
    }
  }

  .language {
    margin: 10px 15px 0 15px;
    border-radius: 16px;
  }
}
</style>
